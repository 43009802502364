import { gql } from '@apollo/client'

export const GET_CLIENT_DETAIL_QUERY = gql`
  query GetClient($slug: String!) {
    clientBySlug(slug: $slug) {
      _id
      slug
      name
      active
      body
      applyNowTxt
      trackingNumbers {
        routingNumber
        trackingNumber
      }
      resources {
        hero {
          Location
          Key
        }
        logo {
          Location
          Key
        }
      }
      createdAt
      defaultCampaign {
        _id
        title

        client {
          name
          applyNowTxt
        }
        description
        isDefault
        percentageLimiter
        createdAt

        leadFormId
        jobPostFormId
        campaignPackageId
        active
      }
      tags {
        dictionaryId
        title
      }
      division {
        dictionaryId
        title
      }

      defaultJobPost {
        _id
        formId
        includeCompanyInfo
        title
        applyURL
        slug
        overview
        details
        campaignId
        includeCompanyInfo
        clientId
        allStatesCoordinates
        hiringArea {
          rules {
            in {
              hasAreaFilter # this field is in use, in order to load all state poligons
              states
              cities {
                city {
                  geo {
                    coordinates
                  }
                }
                radius
              }
              polygons {
                type
                coordinates
              }
              statesPolygon
              zipCodes {
                radius
                geo {
                  type
                  coordinates
                }
              }
            }
          }
        }
        client {
          name
          applyNowTxt
        }
        featured
        isDefault
      }
    }
  }
`

export const PUBLISHED_CLIENT_SLUGS = gql`
  query ($query: ClientByJobQuery!) {
    clientsByJobPostFieldsV2(query: $query) {
      items {
        slug
      }
    }
  }
`

export const GET_ALL_CLIENT_NAMES = gql`
  query ($query: ClientByJobQuery!) {
    clientsByJobPostFieldsV2(query: $query) {
      items {
        name
        _id
      }
    }
  }
`
export const GET_COMPANIES_QUERY = gql`
  query ($query: ClientByJobQuery!) {
    clientsByJobPostFieldsV2(query: $query) {
      items {
        _id
        slug
        name
        applyNowTxt
        resources {
          hero {
            Location
          }
          logo {
            Location
          }
        }
        body
        trackingNumbers {
          trackingNumber
          routingNumber
        }
        defaultJobPost {
          _id
          title
          overview
          featured
          videoLink
          applyURL
          slug
          videoPlaceholder {
            Location
          }
        }

        defaultCampaign {
          _id
        }
        campaignPackageWeight
      }
      totalCount
    }
  }
`

export const GET_FEATURES_LIST_QUERY = gql`
  query ($query: ClientByJobQuery!) {
    clientsByJobPostFieldsV2(query: $query) {
      items {
        _id
        name
        defaultJobPost {
          _id
          videoLink
          slug
          videoPlaceholder {
            Location
          }
        }
      }
    }
  }
`
