import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  onClick: () => void
  className: string
}

const ClearButton = ({ children, onClick, className }: Props) => {
  return (
    <button
      type="button"
      className={`${className} w-full text-[18px] font-semibold text-center inline-block px-6 py-2.5 bg-dim-gray-600 text-white text-xs leading-tight uppercase rounded shadow-md hover:bg-dim-gray-700 hover:shadow-lg focus:bg-dim-gray-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dim-gray-800 active:shadow-lg transition duration-150 ease-in-out`}
      onClick={onClick}>
      {children}
    </button>
  )
}

export default ClearButton
