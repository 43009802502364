import { useCallback, useState } from 'react'

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { CompanyOption } from '../../types/Company'
import { GET_ALL_CLIENT_NAMES } from '../../graphql/company'
import apolloClient from '../../apollo-client'

interface Props {
  placeholder?: string
  controlName?: string
  control: Control<any, any>
}

const CompanyFilterInput = React.forwardRef(
  ({ placeholder, control, controlName }: Props, ref) => {
    const [clients, setClients] = useState<CompanyOption[]>([])
    const [clientsLoaded, setClientsLoaded] = useState<boolean>(false)
    const [clientsLoading, setClientsLoading] = useState<boolean>(false)

    const loadClientNames = useCallback(async () => {
      if (clientsLoaded || clientsLoading) {
        return
      }
      setClientsLoading(true)
      const { data } = await apolloClient.query({
        query: GET_ALL_CLIENT_NAMES,
        variables: {
          query: {
            limit: 1000
          }
        }
      })

      setClients(data.clientsByJobPostFieldsV2.items)

      setClientsLoaded(true)
      setClientsLoading(false)
    }, [clientsLoaded, clientsLoading])

    return (
      <Controller
        name={controlName || 'client'}
        control={control}
        defaultValue={{ name: '', _id: '' }}
        render={({ field }) => (
          <Autocomplete
            {...field}
            size="small"
            fullWidth={true}
            ref={ref}
            freeSolo
            classes={{ root: 'bg-white', inputRoot: 'rounded-none' }}
            isOptionEqualToValue={(
              option: CompanyOption,
              value: CompanyOption
            ) => option._id === value._id}
            getOptionLabel={(option: CompanyOption | string) => {
              if (typeof option === 'string') return ''
              return option.name
            }}
            onOpen={() => {
              loadClientNames()
            }}
            onChange={(_, data) => field.onChange(data)}
            options={clients}
            loading={clientsLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder || 'Company Name'}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,

                  endAdornment: (
                    <>
                      {clientsLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
      />
    )
  }
)

CompanyFilterInput.displayName = 'CompanyFilterInput'
export default CompanyFilterInput
