import { ReactNode, useContext, useEffect } from 'react'
import styled from 'styled-components'
import { ButtonWithArrowUpDown } from '../ui/ButtonWithArrowUpDown'
import { Drawer, IconButton, useMediaQuery, useTheme } from '@mui/material'
import { AiOutlineClose } from 'react-icons/ai'
import GlobalContext from '../../contexts/GlobalContext'

interface Props {
  children: ReactNode
  showAsDockedOnMobile?: boolean
}

const FilterContainer = ({ children, showAsDockedOnMobile = true }: Props) => {
  const { mobileFilterActive, setMobileFilterActive } =
    useContext(GlobalContext)
  const theme = useTheme()

  const matches = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    setMobileFilterActive(false)
  }, [setMobileFilterActive])

  if (showAsDockedOnMobile && matches) {
    return (
      <>
        <Drawer variant="permanent" anchor="bottom">
          <div className="flex justify-between border-solid border-b-[#ebebeb] border-b-2">
            <div> </div>
            <ButtonWithArrowUpDown />
          </div>
        </Drawer>
        <Drawer
          variant="persistent"
          anchor="bottom"
          open={mobileFilterActive}
          onClose={() => setMobileFilterActive()}>
          <Container>
            <div className="flex w-full justify-between">
              <div></div>
              <IconButton
                className="flex justify-between !text-white"
                onClick={() => setMobileFilterActive()}>
                <AiOutlineClose />
              </IconButton>
            </div>

            <Filters>{children}</Filters>
          </Container>
        </Drawer>
      </>
    )
  }

  return (
    <>
      <Container>
        <Filters>{children}</Filters>
      </Container>
    </>
  )
}

export default FilterContainer

const Filters = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  justify-content: space-between;
  padding: 8px 0;
  .ant-select-selector {
    border-radius: 0px !important;
  }
  .ant-form-item-control-input {
    height: 100% !important;
  }

  .ant-form-item-row {
    height: 100% !important;
  }
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #182540;
`
