import Image, { StaticImageData } from 'next/legacy/image'
import Link from 'next/link'
import classes from './Card.module.scss'
import StarBadge from './StarBadge'
import DOMPurify from 'isomorphic-dompurify'
import { limitText } from '../../../lib/Utils'
import DefaultHero from '../../../public/assets/img/company/default.webp'

interface Props {
  href: string
  title: string
  overview?: string
  phoneNumber?: string
  image?: string | StaticImageData
  starBadge?: boolean
  hideFooter?: boolean
  hideProfile?: boolean
  className?: string
  onApplyNow?: () => void
  limitOverviewText?: number
}
export default function Card({
  href,
  title,
  image,
  overview,
  phoneNumber = '',
  starBadge = false,
  hideFooter = false,
  hideProfile = false,
  className = '',
  onApplyNow,
  limitOverviewText
}: Props) {
  const showNumber = () => {
    if (phoneNumber)
      return (
        <Link
          href={`tel:${phoneNumber}`}
          type="button"
          className="max-[450px]:text-[14px] w-full text-[18px] font-semibold text-center inline-block py-2.5 bg-green-600 text-white leading-tight uppercase rounded shadow-md hover:bg-green-700 hover:shadow-lg focus:bg-green-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
          {phoneNumber}
        </Link>
      )
    else {
      return null
    }
  }
  const showProfile = () => {
    if (!hideProfile)
      return (
        <Link
          href={href}
          className="max-[450px]:text-[14px] w-full text-[18px] font-semibold text-center inline-block py-2.5 bg-dark-blue-600 text-white leading-tight uppercase rounded shadow-md hover:bg-dark-blue-700 hover:shadow-lg focus:bg-dark-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-dark-blue-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
          Profile
        </Link>
      )
    else {
      return null
    }
  }

  const getFooter = () => {
    if (hideFooter) return null
    return (
      <div className={`flex  flex-col justify-end	`}>
        <div className="flex">
          {showProfile()}
          {showNumber()}
        </div>
        <button
          onClick={() => onApplyNow && onApplyNow()}
          type="button"
          className="max-[450px]:text-[14px] inline-block font-semibold text-[20px] mt-[12px] px-6 py-2.5 bg-red-600 text-white leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out ml-1">
          apply now
        </button>
      </div>
    )
  }

  const getOverview = () => {
    const cleaned = DOMPurify.sanitize(overview || '')
    return limitOverviewText ? limitText(cleaned, limitOverviewText) : cleaned
  }

  return (
    <div className={`${classes.cardContainer} ${className}`}>
      {starBadge && <StarBadge />}
      <div className={classes.card}>
        <Link
          style={{ position: 'relative', display: 'block', flex: 1 }}
          href={`${href}`}>
          <Image
            alt={title}
            src={image || DefaultHero}
            layout="responsive"
            objectPosition="center"
            objectFit="cover"
            width={1200}
            height={600}
            sizes="(min-width: 60em) 24vw,
            (min-width: 28em) 45vw,
            100vw"
          />
          <div
            className={`${classes.titleWrapper} p-2 flex flex-col justify-around	text-[#777]`}>
            <div>
              <h1 className="font-bold card-title">{title}</h1>
            </div>
            <div className="overflow-hidden h-[120px]">
              <div
                dangerouslySetInnerHTML={{
                  __html: getOverview()
                }}
                className="m-0 card-body"></div>
            </div>
          </div>
        </Link>
        {getFooter()}
      </div>
    </div>
  )
}
