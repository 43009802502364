type Props = {
  visible: boolean
}

export default function NoResult({ visible = false }: Props) {
  if (!visible) {
    return null
  }
  return (
    <div className="flex justify-center items-center">
      <p className="uppercase font-normal pt-[20px] pb-[35px] sm:text-[22px] h-[60px] break-words text-[14px]">
        Your search produced no results...
      </p>
    </div>
  )
}
