import { useContext } from 'react'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import GlobalContext from '../../contexts/GlobalContext'

export function ButtonWithArrowUpDown() {
  const { mobileFilterActive, setMobileFilterActive } =
    useContext(GlobalContext)

  const handleButtonClick = () => {
    setMobileFilterActive()
  }

  return (
    <button
      className="border-solid border-l-[#ebebeb] border-l-2  h-full  font-semibold text-center pl-2 pr-[3px] py-2.5  text-[#777] leading-tight uppercase   hover:shadow-lg  focus:shadow-lg focus:outline-none focus:ring-0  active:shadow-lg transition duration-150 ease-in-out"
      onClick={handleButtonClick}>
      <div className="flex justify-between w-full">
        <div className="pr-2 text-[13px]">
          {!mobileFilterActive ? 'Show Filters' : 'Hide Filters'}
        </div>
        {mobileFilterActive ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
      </div>
    </button>
  )
}
