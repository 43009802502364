import { useEffect } from 'react'
import useLoadVocabulary from '../../hooks/useLoadVocabulary'
import Checkbox from '@mui/material/Checkbox'

import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import { ListItemIcon } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { KeyValPair } from '../../types/Common'

interface Props {
  vocabularyKey?: string
  placeholder: string
  controlName: string
  control: Control<any, any>
  staticData?: KeyValPair[]
  fieldNames?: { fieldValue?: string; fieldLabel?: string }
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const SingleLineMultiSelect = React.forwardRef(
  (
    {
      vocabularyKey,
      placeholder,
      staticData,
      fieldNames: { fieldValue = '_id', fieldLabel = 'title' } = {},
      control,
      controlName
    }: Props,
    ref
  ) => {
    const {
      load,
      loaded,
      loading,
      data = [],
      setData
    } = useLoadVocabulary(vocabularyKey)

    useEffect(() => {
      if (staticData) {
        setData(staticData)
      }
    }, [staticData, setData])

    return (
      <Controller
        name={controlName}
        defaultValue={[]}
        render={({ field }) => (
          <Select
            className="rounded-none"
            {...field}
            onChange={(event) => {
              const value = event.target.value

              field.onChange(value)
            }}
            size="small"
            ref={ref}
            fullWidth={true}
            multiple
            placeholder={placeholder}
            classes={{
              multiple: '!bg-white !rounded-none',
              select: `${!field.value.length ? '!text-[#777]' : ''}`
            }}
            value={field.value}
            onOpen={() => {
              !staticData && !loaded && !loading && load()
            }}
            renderValue={(selected) => {
              if (!data || !data.length) {
                return placeholder
              }
              return selected?.length
                ? selected
                    .map(
                      (s: any) =>
                        data.find((d) => d[fieldValue] === s)![fieldLabel]
                    )
                    .join(', ')
                : placeholder
            }}
            displayEmpty
            MenuProps={{
              autoFocus: false,
              PaperProps: {
                style: {
                  maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP
                }
              },
              MenuListProps: { disablePadding: true },
              anchorPosition: {
                top: 0,
                left: 0
              },
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'center'
              }
            }}>
            {loading
              ? [
                  <MenuItem key="loading" value="loading">
                    <ListItemText primary="Loading..." />
                  </MenuItem>
                ]
              : [
                  data.map((option) => (
                    <MenuItem
                      key={option[fieldValue]}
                      value={option[fieldValue]}>
                      <ListItemIcon>
                        <Checkbox
                          checked={field.value.indexOf(option[fieldValue]) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option[fieldLabel]} />
                    </MenuItem>
                  ))
                ]}
          </Select>
        )}
        control={control}></Controller>
    )
  }
)

SingleLineMultiSelect.displayName = 'SingleLineMultiSelect'
export default SingleLineMultiSelect
